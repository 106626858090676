import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import styled from 'styled-components';
import { Button } from '../Button/Button';
import { tokens } from './tokens';
export const TableIconButton = forwardRef((props, ref) => (_jsx(StyledIconButton, Object.assign({}, props, { ref: ref, size: 'm', variant: 'linkGrey' }))));
export const StyledExpandIcon = styled(TableIconButton) `
  // We need to adjust padding from the cell which has expand icon.
  margin-left: -${tokens.Cell.padding};
`;
// remove 1px from height for border-bottom of table cell
export const StyledIconButton = styled(Button) `
  height: ${parseInt(tokens.Row.height) - 1}px;
  width: ${tokens.Row.height};
  color: ${tokens.Icon.color};

  &&:hover:not([disabled]) {
    color: ${tokens.Icon.hover.color};
  }
`;
