import { __rest } from "tslib";
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useControlledState } from '@prophecy/utils/react/hooks';
import { ReactComponent as SuccessStatusIcon } from '../assets/status/successStatus.svg';
import { CheckPenIcon, ChevronRightIcon } from '../Icons';
import { Stack } from '../Layout';
import { TabsList, TabsTrigger } from '../Tabs';
import { theme } from '../theme';
import { Tooltip } from '../Tooltip';
import { Text } from '../Typography';
import { NoWrapStackItem, StepIconContainer, StyledSeparatorBar, StepIconBadge, StyledTabsRoot } from './styled';
import { tokens } from './tokens';
import { StepState } from './types';
export * from './hooks';
function getStepState(step, current) {
    if (step === current) {
        return StepState.active;
    }
    else if (step < current) {
        return StepState.completed;
    }
    else {
        return StepState.pending;
    }
}
export function Stepper(_a) {
    var { current, defaultCurrent, onChange, steps, filled = true, orientation = 'horizontal', separator = orientation === 'horizontal' ? (_jsx(ChevronRightIcon, { type: 'default', size: 'xs', color: tokens.Separator.color })) : null } = _a, rest = __rest(_a, ["current", "defaultCurrent", "onChange", "steps", "filled", "orientation", "separator"]);
    const [currentStep = 0, setCurrentStep] = useControlledState({
        value: current,
        defaultValue: defaultCurrent,
        onChange
    });
    const onTabChange = (value) => {
        setCurrentStep(Number(value));
    };
    return (_jsx(StyledTabsRoot, Object.assign({ value: currentStep.toString(), onValueChange: onTabChange, dir: 'ltr', filled: filled, orientation: 'horizontal' }, rest, { children: _jsx(TabsList, { children: _jsx(Stack, { direction: orientation, alignY: orientation === 'horizontal' ? 'center' : 'start', gap: tokens.StepList[orientation].gap, children: steps.map((_a, i) => {
                    var { title, label } = _a, stepProps = __rest(_a, ["title", "label"]);
                    const state = getStepState(i, currentStep);
                    const disabled = stepProps.disabled;
                    return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: title, children: _createElement(TabsTrigger, Object.assign({}, stepProps, { value: i.toString(), key: i }),
                                    _jsxs(Stack, { direction: 'horizontal', alignY: 'center', gap: filled ? tokens.Step.gap : tokens.Step.nonFilledGap, children: [filled && state === StepState.completed ? (_jsx(StepIconContainer, { children: _jsx(CheckPenIcon, { color: disabled ? tokens.StepIcon.disabled.iconColor : tokens.StepIcon.completed.iconColor, type: 'extended', size: 's' }) })) : (_jsx(StepIconBadge, { disabled: disabled, filled: filled, state: state, children: state === StepState.completed ? (_jsx(SuccessStatusIcon, {})) : (_jsx(Text, { level: 'sm', tone: disabled ? tokens.StepIcon.disabled.color : undefined, children: _jsx("b", { children: i + 1 }) })) })), _jsx(NoWrapStackItem, { grow: '1', state: filled ? undefined : state, children: _jsx(Text, { level: 'sm', weight: theme.fontWeight.medium, tone: tokens.StepIcon[state].color, children: label }) })] })) }), i < steps.length - 1 &&
                                (filled ? separator : _jsx(StyledSeparatorBar, { state: state, orientation: 'vertical' }))] }));
                }) }) }) })));
}
