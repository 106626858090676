import { isTextBoxActive, isTextSelected } from '@prophecy/utils/dom';
import { findNextEmptyCell } from '@prophecy/utils/graph';
import { KeyCodes } from '@prophecy/utils/keyCodes';
import { useAutoFocus } from '@prophecy/utils/react/focus'; // eslint-disable-line import/no-webpack-loader-syntax
import { usePersistentCallback } from '@prophecy/utils/react/hooks';
import { zoomIdentity } from 'd3-zoom';
import { noop } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getConnectedEdges, getRectOfNodes, getTransformForBounds, useEdges, useNodes, useReactFlow, useStoreApi } from 'reactflow';
import { isDialogOpened } from '../Dialog';
import { useGraphContext } from './GraphContext';
import { defaultFilterNodes, isGraphPresent, stripNodeProperties, isNodeNotPositioned, getNodeRect, snapPosition } from './utils';
export const MouseSelectionKey = KeyCodes._None;
export const IDE_Clipboard_Data_Format = 'application/prophecy-ide-format';
function getSelectedGraph(_nodes, _edges) {
    const selectedNodeIds = [];
    const inBoundEdges = [];
    const edges = _edges;
    const nodes = [];
    _nodes.forEach((node) => {
        selectedNodeIds.push(node.id);
        nodes.push(stripNodeProperties(node));
    });
    const connectedEdges = getConnectedEdges(nodes, edges);
    connectedEdges.forEach((edge) => {
        if (selectedNodeIds.includes(edge.source) && selectedNodeIds.includes(edge.target)) {
            inBoundEdges.push(edge);
        }
    });
    return { nodes, edges: inBoundEdges };
}
function defaultCopy(a) {
    return a;
}
export function useSelectedElements() {
    const nodes = useNodes();
    const edges = useEdges();
    const selectedNodes = nodes.filter((node) => node.selected);
    const selectedEdges = edges.filter((edge) => edge.selected);
    return { selectedNodes, selectedEdges };
}
function isCutCopyAllowed() {
    // if user has selected some other text, or user is inside input/textarea or dialog, don't allow cut and copy
    return !(isTextSelected() || isTextBoxActive() || isDialogOpened()) && isGraphPresent();
}
export function useCopyPaste(onCut = defaultCopy, onCopy = defaultCopy, onPaste = noop, mousePositionRef, readOnly) {
    const { selectedNodes, selectedEdges } = useSelectedElements();
    const _onCut = usePersistentCallback(onCut);
    const _onCopy = usePersistentCallback(onCopy);
    const _onPaste = usePersistentCallback(onPaste);
    useEffect(() => {
        function cut(event) {
            if (!isCutCopyAllowed()) {
                return;
            }
            if (selectedNodes && selectedNodes.length) {
                const { nodes, edges } = getSelectedGraph(selectedNodes, selectedEdges);
                const data = _onCut({ nodes, edges });
                if (!data)
                    return;
                event.clipboardData.setData(IDE_Clipboard_Data_Format, JSON.stringify(data));
                event.preventDefault();
            }
        }
        function copy(event) {
            if (!isCutCopyAllowed()) {
                return;
            }
            // const selectedElements = store.selectedElements;
            if (selectedNodes && selectedNodes.length) {
                const { nodes, edges } = getSelectedGraph(selectedNodes, selectedEdges);
                const data = _onCopy({ nodes, edges });
                if (!data)
                    return;
                event.clipboardData.setData(IDE_Clipboard_Data_Format, JSON.stringify(data));
                event.preventDefault();
            }
        }
        function paste(event) {
            if (isDialogOpened() || !isGraphPresent() || isTextBoxActive()) {
                return;
            }
            try {
                const dataStr = event.clipboardData.getData(IDE_Clipboard_Data_Format);
                const data = JSON.parse(dataStr);
                _onPaste(data, mousePositionRef.current);
            }
            catch (error) {
                console.error(error);
            }
            finally {
                event.preventDefault();
            }
        }
        if (!readOnly) {
            document.addEventListener('cut', cut);
            document.addEventListener('paste', paste);
        }
        document.addEventListener('copy', copy);
        return () => {
            if (!readOnly) {
                document.removeEventListener('cut', cut);
                document.removeEventListener('paste', paste);
            }
            document.removeEventListener('copy', copy);
        };
    }, [_onCopy, _onCut, _onPaste, mousePositionRef, readOnly, selectedEdges, selectedNodes]);
}
export function withPadding(boundary, padding) {
    return [
        [boundary[0][0] - padding, boundary[0][1] - padding],
        [boundary[1][0] + padding, boundary[1][1] + padding]
    ];
}
export function useBoundary(nodes, graphKey, padding = 200) {
    // get nodes bound
    const store = useStoreApi();
    const [initialized, initialize] = useState();
    useEffect(() => {
        initialize(false);
    }, [graphKey]);
    const [initializeCounter, setInitializeCounter] = useState(0);
    const previousBoundary = useRef();
    const { width, height, transform = [0, 0] } = store.getState();
    const bounds = getRectOfNodes(nodes);
    const initializeBoundary = usePersistentCallback(() => {
        // on initialize clear previous boundary
        previousBoundary.current = undefined;
        // on every initialize make sure the boundary is recalculated, we do it by triggering a rerender based on counter
        setInitializeCounter(initializeCounter + 1);
        initialize(true);
    });
    const hasUnLayoutedNodes = nodes.some(isNodeNotPositioned);
    let boundary;
    // return boundary based on viewport size, if positions are not initialized or nodes are not present
    if (!nodes.length || !initialized || isNaN(bounds.width) || isNaN(bounds.height)) {
        boundary = [
            [0, 0],
            [width, height]
        ];
        // if it is initialized but unlayouted node, default to previous boundary
    }
    else if (hasUnLayoutedNodes) {
        boundary = previousBoundary.current || [
            [0, 0],
            [width, height]
        ];
    }
    else {
        /**
         * as we do want to center align the graph at any time, so for bounding box of graph
         * we want to keep it minimum to the viewport size.
         */
        const extraPaddingX = width > bounds.width ? (width - bounds.width) / 2 : 0;
        const extraPaddingY = height > bounds.height ? (height - bounds.height) / 2 : 0;
        const viewportX = -transform[0];
        const viewportY = -transform[1];
        /**
         * For boundary calculation we need to get the bound of two rectangles
         * one the current viewport of canvas and second the bounding box
         */
        const minBoundX = Math.min(bounds.x - extraPaddingX, viewportX);
        const minBoundY = Math.min(bounds.y - extraPaddingY, viewportY);
        const maxBoundX = Math.max(bounds.x + bounds.width + extraPaddingX, viewportX + width);
        const maxBoundY = Math.max(bounds.y + bounds.height + extraPaddingY, viewportY + height);
        // previous boundary is not set add it, Take initial transform into consideration to avoid flicker,
        if (!previousBoundary.current) {
            previousBoundary.current = [
                [minBoundX, minBoundY],
                [maxBoundX, maxBoundY]
            ];
        }
        const _previousBoundary = previousBoundary.current;
        /**
         * Don't let the boundary reduce beyond previous boundary,
         * or else it will cause flicker on scroll.
         */
        boundary = [
            [Math.min(minBoundX, _previousBoundary[0][0]), Math.min(minBoundY, _previousBoundary[0][1])],
            [Math.max(maxBoundX, _previousBoundary[1][0]), Math.max(maxBoundY, _previousBoundary[1][1])]
        ];
        previousBoundary.current = boundary;
        boundary = withPadding(boundary, padding);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const memoizedBoundary = useMemo(() => boundary, [JSON.stringify(boundary)]);
    return { initializeBoundary, boundary: memoizedBoundary, boundaryInitialized: Boolean(initialized) };
}
export function useFitView(filter = defaultFilterNodes, applyZoom = false) {
    const store = useStoreApi();
    const instance = useReactFlow();
    return usePersistentCallback(() => {
        const { width, height, transform, d3Zoom, d3Selection } = store.getState();
        const maxZoom = 1 / 1.2;
        const minZoom = 0.65;
        const nodes = instance.getNodes();
        const _nodes = nodes.filter(filter);
        if (!_nodes.length || !d3Zoom || !d3Selection) {
            return;
        }
        const currentZoom = transform[2];
        const bounds = getRectOfNodes(_nodes);
        let [x, y, zoom] = getTransformForBounds(bounds, width, height, applyZoom ? minZoom : currentZoom, applyZoom ? maxZoom : currentZoom, 0.1);
        // if the bounds with new zoom doesn't fit on view port,
        if (bounds.width * zoom > width || bounds.height * zoom > height) {
            const { nodeInternals } = store.getState();
            // try to bring the left and top most in the view port.
            const nodesOnLeftEdge = _nodes.filter((node) => getNodeRect(node, nodeInternals).x === bounds.x);
            // sort the node based on y position
            const referenceNode = nodesOnLeftEdge.sort((node1, node2) => {
                return getNodeRect(node1, nodeInternals).y - getNodeRect(node2, nodeInternals).y;
            })[0];
            const referenceNodeRect = getNodeRect(referenceNode, nodeInternals);
            const padding = 50;
            // if horizontal bound is not fitting on viewport update x
            x = bounds.width * zoom > width ? -(referenceNodeRect.x - padding) * zoom : x;
            // if vertical bound not fitting on view port update y
            // For y try to center reference node, but if vertically centering reference node brings the bound inside the viewport,
            // reset to the bound, so more nodes can be show
            y =
                bounds.height * zoom > height
                    ? -(Math.max(bounds.y * zoom, referenceNodeRect.y * zoom - height / 2) - padding * zoom)
                    : y;
        }
        const newTransform = zoomIdentity.translate(x, y).scale(zoom);
        d3Zoom.transform(d3Selection, newTransform);
    });
}
export function useRestoreOrFitView({ filter = defaultFilterNodes, applyZoom = false, reLayout = false, graphId }) {
    const fitView = useFitView(filter, applyZoom);
    const { viewPortCache } = useGraphContext();
    const { getViewport, setViewport, getNodes } = useReactFlow();
    const _getViewport = usePersistentCallback(getViewport);
    const _getNodes = usePersistentCallback(getNodes);
    // if graph requires re layout clear the key
    useEffect(() => {
        if (reLayout) {
            viewPortCache.delete(graphId);
        }
    }, [graphId, reLayout, viewPortCache]);
    useEffect(() => {
        return () => {
            // store the viewport information if nodes are present
            if (_getNodes().length) {
                viewPortCache.set(graphId, _getViewport());
            }
        };
    }, [graphId, _getNodes, viewPortCache, _getViewport]);
    const restoreOrFitView = usePersistentCallback(() => {
        const viewPort = viewPortCache.get(graphId);
        if (viewPort && getNodes().length) {
            setViewport(viewPort);
        }
        else {
            fitView();
        }
    });
    return { restoreOrFitView, fitView };
}
export function useEmptyCell() {
    const store = useStoreApi();
    return (nodePoints) => {
        const { width: containerWidth, height: containerHeight, transform: [tX, tY, tScale] } = store.getState();
        const containerPadding = 50;
        // start the position from factor of grid size
        const { x: leftCornerX, y: leftCornerY } = snapPosition({ x: -tX / tScale, y: -tY / tScale });
        return findNextEmptyCell({
            points: nodePoints,
            height: (containerHeight - containerPadding) / tScale,
            width: (containerWidth - containerPadding) / tScale,
            leftCornerX,
            leftCornerY
        });
    };
}
export function useKeyBindings({ autoFocus, initialized, container, openSearch }) {
    const { getNodes, getEdges, setEdges, setNodes } = useReactFlow();
    const nodes = getNodes();
    const edges = getEdges();
    useAutoFocus(initialized && autoFocus, container);
    const handleSelectAll = (e) => {
        if ((e.metaKey || e.ctrlKey) && e.key === KeyCodes.A) {
            e.preventDefault();
            const _nodes = [...nodes].map((node) => (Object.assign(Object.assign({}, node), { selected: !node.data.virtual })));
            const _edges = [...edges].map((edge) => { var _a; return (Object.assign(Object.assign({}, edge), { selected: !((_a = edge.data) === null || _a === void 0 ? void 0 : _a.virtual) })); });
            setNodes(_nodes);
            setEdges(_edges);
        }
    };
    const handleSearch = (e) => {
        if (e.key === KeyCodes.F && (e.metaKey || e.ctrlKey) && openSearch) {
            e.preventDefault();
            openSearch();
        }
    };
    return (e) => {
        if (!initialized || isTextBoxActive()) {
            return;
        }
        handleSelectAll(e);
        handleSearch(e);
    };
}
