import { ProcessKind } from '../graph/types';

export enum Entity {
  User = 'User',
  Project = 'Project',
  Package = 'Package',
  Pipeline = 'Pipeline',
  PipelineConfiguration = 'PipelineConfigurations',
  Configuration = 'Configuration',
  Subgraph = ProcessKind.Subgraph,
  Job = 'Job',
  SQL = 'Sql',
  Gem = 'Gem',
  Fabric = 'Fabric',
  UDF = 'UDF',
  Dataset = 'Dataset',
  Team = 'Team',
  Lineage = 'Lineage',
  Template = 'Template',
  Expectation = 'Expectation',
  Observation = 'Observation',
  Member = 'Member',
  writeAndReadPipelines = 'writeAndReadPipelines',
  writeAndReadJobs = 'writeAndReadJobs',
  readDatasets = 'readDatasets',
  writeDatasets = 'writeDatasets',
  Repository = 'Repository',
  Model = 'Model',
  Snapshot = 'Snapshot',
  Macro = 'Macro',
  Seed = 'Seed',
  Function = 'Function',
  Source = 'Source',
  Transpiler = 'Transpiler',
  UnreferencedSource = 'UnreferencedSource',
  Table = 'Table',
  Connector = 'Connector',
  JobSubgraph = 'JobSubgraph',
  Test = 'Test',
  ModelTest = 'ModelTest',
  SingularDataTest = 'SingularDataTest',
  GenericDataTest = 'GenericDataTest'
}

export type IDEEntity =
  | Entity.Configuration
  | Entity.Pipeline
  | Entity.Job
  | Entity.SQL
  | Entity.Lineage
  | Entity.Observation
  | Entity.Gem
  | Entity.Project;

export type EntityWitUrl =
  | Entity.Dataset
  | Entity.Model
  | Entity.Pipeline
  | Entity.Configuration
  | Entity.Job
  | Entity.SQL
  | Entity.Lineage
  | Entity.Observation
  | Entity.Subgraph
  | Entity.Project
  | Entity.Gem;

export type EntityToCreate = Entity.Pipeline | Entity.Job | Entity.Gem | undefined;
export function getSqlChildEntity(entity: Entity) {
  return `sql${entity}`;
}

export function getSqlMetadataEntity(entity: Entity) {
  return `SQL${entity}`;
}

export function sqlMetadataEntityToEntity(entity: string) {
  return entity.replace(/^SQL/, '') as Entity;
}
